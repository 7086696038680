import React, { useState } from "react";

const TaskList = ({ tasks, addTask, completeTask, updateTask, deleteTask }) => {
  const [taskName, setTaskName] = useState("");
  const [taskPriority, setTaskPriority] = useState("baja");
  const [taskDueDate, setTaskDueDate] = useState("");
  const [editingTaskId, setEditingTaskId] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingTaskId) {
      updateTask(editingTaskId, {
        name: taskName,
        priority: taskPriority,
        dueDate: taskDueDate,
      });
      setEditingTaskId(null);
    } else {
      addTask({ name: taskName, priority: taskPriority, dueDate: taskDueDate });
    }
    setTaskName("");
    setTaskPriority("baja");
    setTaskDueDate("");
  };

  const handleEdit = (task) => {
    setTaskName(task.name);
    setTaskPriority(task.priority);
    setTaskDueDate(task.dueDate);
    setEditingTaskId(task.id);
  };

  return (
    <div>
      <h3>Tareas Pendientes</h3>
      <form onSubmit={handleSubmit} className="mb-3">
        <div className="row">
          <div className="col-6">
            <input
              type="text"
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
              placeholder="Nombre de la tarea"
              required
              className="form-control mb-2"
            />
          </div>
          <div className="col-2">
            <select
              value={taskPriority}
              onChange={(e) => setTaskPriority(e.target.value)}
              className="form-select mb-2"
            >
              <option value="baja">Baja</option>
              <option value="media">Media</option>
              <option value="alta">Alta</option>
            </select>
          </div>
          <div className="col-2">
            <input
              type="date"
              value={taskDueDate}
              onChange={(e) => setTaskDueDate(e.target.value)}
              required
              className="form-control mb-2"
            />
          </div>
          <div className="col-2">
            <button type="submit" className="btn btn-primary">
              {editingTaskId ? "Actualizar Tarea" : "Agregar Tarea"}
            </button>
          </div>
        </div>
      </form>
      <table className="table table-striped">
        <thead>
        <tr>
          <th style={{ width: '40%' }}>Nombre</th>
          <th style={{ width: '10%' }}>Prioridad</th>
          <th style={{ width: '20%' }}>Fecha de Vencimiento</th>
          <th style={{ width: '30%' }}>Acciones</th>
        </tr>

        </thead>
        <tbody>
          {tasks
            .filter((task) => !task.completed)
            .map(
              (
                task // Filtrar solo tareas no completadas
              ) => (
                <tr key={task.id}>
                  <td>{task.name}</td>
                  <td>{task.priority}</td>
                  <td>{task.dueDate}</td>
                  <td>
                    <button
                      className="btn btn-warning btn-sm"
                      onClick={() => handleEdit(task)}
                    >
                      Editar
                    </button>
                    <button
                      className="btn btn-danger btn-sm ms-2"
                      onClick={() => deleteTask(task.id)}
                    >
                      Eliminar
                    </button>
                    <button
                      className="btn btn-success btn-sm ms-2"
                      onClick={() => completeTask(task.id)}
                    >
                      Completar
                    </button>
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
    </div>
  );
};

export default TaskList;
