import React from 'react';

const CompletedTaskList = ({ tasks, projectId }) => {
  return (
    <div>
      <h3>Tareas Completadas</h3>
      <table className="table table-success table-striped">
        <thead>
          <tr>
            <th style={{ width: '70%' }}>Nombre</th>
            <th style={{ width: '15%' }}>Prioridad</th>
            <th style={{ width: '15%' }}>Fecha de Vencimiento</th>
          </tr>
        </thead>
        <tbody>
          {tasks.filter(task => task.completed).map((task) => (
            <tr key={task.id}>
              <td>{task.name}</td>
              <td>{task.priority}</td>
              <td>{task.dueDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompletedTaskList;
