import React, { useState } from 'react';
import Sidebar from './components/Sidebar';
import TaskList from './components/TaskList';
import CompletedTaskList from './components/CompletedTaskList';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import flyLogo from './assets/img/fly.png';

const App = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [tasks, setTasks] = useState([]); // Estado para tareas

  const addProject = (name, color) => {
    const newProject = { id: Date.now(), name, color };
    setProjects([...projects, newProject]);
  };

  const selectProject = (projectId) => {
    const project = projects.find((p) => p.id === projectId);
    setSelectedProject(project);
  };

  const addTask = (task) => {
    const newTask = { id: Date.now(), ...task, completed: false, projectId: selectedProject.id }; // Asocia la tarea al proyecto seleccionado
    setTasks([...tasks, newTask]);
  };

  const completeTask = (taskId) => {
    setTasks(tasks.map(task => task.id === taskId ? { ...task, completed: true } : task));
  };

  const updateTask = (id, updatedTask) => {
    setTasks(tasks.map(task => (task.id === id ? { ...task, ...updatedTask } : task)));
  };

  const deleteTask = (id) => {
    setTasks(tasks.filter(task => task.id !== id));
  };

  return (
    <>
      <header className="pt-4 pb-4 d-flex justify-content-center">
        <div className="text-center">
          <h1 className="mt-3 text-light fw-bold">GESTOR DE TAREAS</h1> {/* Añadido margen superior para separación */}
        </div>
      </header>
  
      <div className="d-flex container-flex"> {/* Añadimos la clase container-flex */}
        <Sidebar projects={projects} addProject={addProject} selectProject={selectProject} />
        <div className="container p-4">
          {selectedProject ? (
            <div
              style={{
                backgroundColor: selectedProject.color, // Usamos el color del proyecto seleccionado
                padding: '10px',
                borderRadius: '5px',
                marginBottom: '20px'
              }}
            >
              <h2 className="text-dark">{selectedProject.name}</h2> {/* Cambiamos el color del texto si es necesario */}
            </div>
          ) : (
            <h2>Selecciona un Proyecto</h2>
          )}
          {selectedProject && (
            <>
              <TaskList
                projectId={selectedProject.id}
                addTask={addTask}
                completeTask={completeTask}
                updateTask={updateTask}
                deleteTask={deleteTask}
                tasks={tasks.filter(task => task.projectId === selectedProject.id)} // Filtrar tareas por projectId
              />
              <CompletedTaskList
                tasks={tasks.filter(task => task.projectId === selectedProject.id)} // Filtrar tareas completadas por projectId
                projectId={selectedProject.id} />
            </>
          )}
        </div>
      </div>
  
      <footer className='pt-3 pb-3 bg-black'>
        <div className="d-flex justify-content-center align-items-center">
          <p className='text-light mb-0 me-1'> Desarrollado por </p>
          <a className='text-light' href='https://flyweb.com.ar' target='_blank' rel='noreferrer'> Fly Web</a>
          <img src={flyLogo} alt="Logo Fly" width={100} />
        </div>
      </footer>
    </>
  );
  
};

export default App;
