import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Sidebar = ({ projects, addProject, selectProject }) => {
  const [projectName, setProjectName] = useState('');
  const [projectColor, setProjectColor] = useState('#ffffff');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (projectName) {
      addProject(projectName, projectColor);
      setProjectName('');
      setProjectColor('#ffffff');
    }
  };

  return (
    <div className="sidebar p-3 bg-light">
      <h3>Proyectos</h3>
      <form onSubmit={handleSubmit} className="mb-3">
        <input
          type="text"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          placeholder="Nombre del proyecto"
          required
          className="form-control mb-2"
        />
        <input
          type="color"
          value={projectColor}
          onChange={(e) => setProjectColor(e.target.value)}
          className="form-control mb-2"
        />
        <button type="submit" className="btn btn-primary">Agregar Proyecto</button>
      </form>
      <ul className="list-group">
        {projects.map((project) => (
          <li
            key={project.id}
            className="list-group-item"
            style={{ backgroundColor: project.color }}
            onClick={() => selectProject(project.id)}
          >
            {project.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
